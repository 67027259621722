import React from 'react'
import "./Introduction.css"
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import NavLinks from '../NavLinks/NavLinks'
import logo from "./../../Image/logo-about.png"
import upgraders from "./../../Image/upgraderes.png"
import one from "./../../Image/one.png"
import oneSection from "./../../Image/جلو امپر.jpg"
import two from "./../../Image/2.png"
import { useEffect } from "react";

export default function About() {
    useEffect(() => {
        document.title = 'معرفی آپگریدر';
      }, [])

      const link =[{
        name:'معرفی آپگریدر',
        link:'/introduction/',
    }];

    return (
        <div>
            <Container>
            <NavLinks {...link} />
            </Container>
            <Container className='about-baner mt-lg-5' fluid>
                <Container>
                    <Row className='' style={{ alignItems: "baseline" }}>
                        <Col className='text-center'>
                            <h2 className='text-center title-about-baner'>
                                <span style={{ color: "#F6A413" }}>آپگریدر</span> هوشمند ساز خودرو
                            </h2>
                            <img className='img-fluid w-75 text-center' src={logo} alt="" />
                        </Col>
                        <Col>
                            <h2 className='text-center lft-title mt-3 d-xl-none'>
                                مخصوص خودرو های خانواده سورن ودنا
                            </h2>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className='bg-light interdus-upgrader-box py-2 py-lg-5 rounded-2'>
                <div className='row'>
                    <div style={{ textAlign: "justify" }} className="col col-9">آپگریدر دستگاهی در ابعاد کوچک است که بدون نیاز به تغییر سیم کشی و تنها با اتصال به پورت عیب یابی خودرو فعال شده و به سلیقه شما شروع به کار می کند!
                        <br></br> به همین راحتی !
                        <span className='abslut-title'>
                            معرفی آپگریدر
                        </span>
                    </div>
                    <div className="col col-3">
                        <img className='img-fluid upgraders' src={upgraders} alt="" />
                    </div>
                </div>
            </Container>
            <Container>
                <h2 className='text-center my-4'>
                    آپشن ها
                </h2>
                <Tabs

                    defaultActiveKey=""
                    id="uncontrolled-tab-example"
                    className="text-center border-0 justify-content-around"

                >
                    <Tab className='tab-content-custom' eventKey="1" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="2" title={<img src={two} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="3" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="4" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
                <div className='my-lg-5'>
                    <Tabs

                        defaultActiveKey=""
                        id="uncontrolled-tab-example"
                        className="text-center border-0 justify-content-around"

                    >
                        <Tab className='tab-content-custom' eventKey="1" title={<img src={one} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                    <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                                </Col>
                                <Col lg={4}>
                                    <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab className='tab-content-custom' eventKey="2" title={<img src={one} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                    <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                                </Col>
                                <Col lg={4}>
                                    <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab className='tab-content-custom' eventKey="3" title={<img src={one} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                    <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                                </Col>
                                <Col lg={4}>
                                    <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab className='tab-content-custom' eventKey="4" title={<img src={one} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                    <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                                </Col>
                                <Col lg={4}>
                                    <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </div>
                <Tabs

                    defaultActiveKey=""
                    id="uncontrolled-tab-example"
                    className="text-center border-0 justify-content-around"

                >
                    <Tab className='tab-content-custom' eventKey="1" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="2" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="3" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="4" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>


            </Container>
        </div>
    )
}
