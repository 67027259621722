import { Col,Row } from 'react-bootstrap'
import "./AllProducts.css"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import CardProduct from '../Index/component/CardProduct'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import useMediaQuery from '@mui/material/useMediaQuery';
import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";



export default function AllProducts() {
    const [sort, setSort] = useState('');

    const handleChange = (event) => {
        setSort(event.target.value);
    };

    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
    const [show, setShow] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        getProducts(currentPage);
        document.title = 'همه محصولات فروشگاه آپگریدر - فروشگاه آپگریدر | ارسال به تمام ایران';

    }, [currentPage]);
    const getProducts = (page) => {
        setLoading(true);
        axiosClient.get(`/getProducts?page=${page}`)
            .then(({ data }) => {
                setLoading(false);
                setProducts(data.data);
                setCurrentPage(data.current_page || data.meta?.current_page || 1);
                setLastPage(data.last_page || data.meta?.last_page || 1);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error fetching products:', error);
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page); 
    };



    return (
        <>
            <div className='d-flex justify-content-between filters-box'>
                <h2>همه محصولات</h2>
                <div className='d-flex'>
                    <div className='d-flex align-items-center swich-box me-1 me-lg-3'>
                        فقط موجود ها
                        <Switch size='small' {...label} color="warning" />

                    </div>
                    <Select
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className='sort-select'
                        defaultValue={-1}
                    >

                        <MenuItem value={-1}>جدیدترین </MenuItem>
                        <MenuItem value={-2}>گرانترین</MenuItem>
                        <MenuItem value={-3}>ارزانترین</MenuItem>
                        <MenuItem value={-4}>پرفروش ترین</MenuItem>
                        <MenuItem value={-5}>امتیاز</MenuItem>
                    </Select>

                </div>
            </div>
            <Row className='row-cols-2 row-cols-lg-3 mt-4 gy-4'>
                {products.map((product, index) => (
                    <Col key={index}>
                        <CardProduct {...product} />
                    </Col>
                ))}
            </Row>
            <div className='pagination-container'>
                <Pagination
                    className="pagination my-5"
                    count={lastPage} // Total number of pages
                    page={currentPage} // Current active page
                    onChange={(_, page) => handlePageChange(page)} // Page change handler
                    siblingCount={isMobile ? 1 : 2} // Number of items in the middle
                    boundaryCount={1} // Number of items at the start and end
                    renderItem={(item) => (
                        <PaginationItem
                            className="custom-pagination-item"
                            slots={{ previous: FiArrowRight, next: FiArrowLeft }} // Custom icons for previous and next
                            {...item}
                        />
                    )}
                />
            </div>

        </>
    )
}
