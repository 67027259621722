import React from 'react'
import "./Contact.css"
import NavLinks from '../NavLinks/NavLinks'
import { Col, Container, Row } from 'react-bootstrap'
import phoneImage from "./../../Image/phone.png"
import balad from "./../../Image/balad.png"
import neshan from "./../../Image/neshan.png"
import veiz from "./../../Image/veiz.png"
import googlemap from "./../../Image/googlemap.png"
import instagramLogo from "./../../Image/instagramLogo.png"
import youtubLogo from "./../../Image/youtubLogo.png"
import aparatLogo from "./../../Image/aparatLogo.png"
import { Link } from 'react-router-dom'
import { useEffect } from "react";

export default function Contact() {
    useEffect(() => {
        document.title = 'تماس با ما - فروشگاه آپگریدر';
    }, [])
    const link = [{
        name: 'تماس با ما',
        link: '/contact-us/',
    }];
    return (
        <Container>
            <NavLinks {...link} />
            <Row className='my-lg-5 gy-4'>
                <Col lg={3} className='contact-boxes  order-2 order-lg-1'>
                    <div>
                        <div className='fs-4 contact-box-title'>شماره تماس</div>
                        <div className='text-center mt-3'>قبل از مراجعه تماس بگیرید</div>
                        <div>
                            <div className='row align-items-center justify-content-center number-box'>
                                <div className="col-4 col-lg-6 text-center"><img className=' w-75' src={phoneImage} alt="phone" /></div>
                                <div className="col-8 col-lg-6 fs-5">09193946701</div>
                            </div>
                            <div className='row align-items-center justify-content-center number-box'>
                                <div className="col-4 col-lg-6 text-center"><img className=' w-75' src={phoneImage} alt="phone" /></div>
                                <div className="col-8 col-lg-6 fs-5">09193946701</div>
                            </div>
                            <div className='row align-items-center justify-content-center number-box'>
                                <div className="col-4 col-lg-6 text-center"><img className=' w-75' src={phoneImage} alt="phone" /></div>
                                <div className="col-8 col-lg-6 fs-5">09193946701</div>
                            </div>


                        </div>
                    </div>
                </Col>
                <Col lg={5} className='contact-boxes mx-lg-4 mx-xl-5  order-1 order-lg-2'>
                    <div>
                        <div className='fs-4 contact-box-title'>آدرس ما : </div>
                        <div className='text-center mt-3 fs-5'>مهرآباد _  بزرگراه فتح (غرب به شرق )_ <br />نرسیده به بیمارستان شریعت رضوی <br /> _ بلوار طالقانی _ خیابان میر قادری _ پلاک 128</div>
                        <div>
                            <div className='text-center mt-5 fs-5'>مسیر یابی در :</div>
                            <div className='d-flex justify-content-center mt-lg-5'>
                                <Link>
                                    <div className='text-center'>
                                        <img className='img-fluid text-center' src={balad} alt="balad" />
                                        <div className='text-center'>
                                            بلد
                                        </div>
                                    </div>
                                </Link>
                                <Link>
                                    <div className='text-center'>
                                        <img className='img-fluid text-center' src={neshan} alt="neshan" />
                                        <div className='text-center'>
                                            نشان
                                        </div>
                                    </div>
                                </Link>
                                <Link>
                                    <div className='text-center'>
                                        <img className='img-fluid text-center' src={googlemap} alt="map" />
                                        <div className='text-center'>
                                            گوگل مپ
                                        </div>
                                    </div>
                                </Link>
                                <Link>
                                    <div className='text-center'>
                                        <img className='img-fluid text-center' src={veiz} alt="veiz" />
                                        <div className='text-center'>
                                            ویز
                                        </div>
                                    </div>
                                </Link>

                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={3} className='contact-boxes  order-3 '>
                    <div>
                        <div className='fs-4 contact-box-title'> شبکه های اجتماعی</div>
                        <div className='text-center mt-3'>مارا دنبال کنید!</div>
                        <div>
                            <div className='row align-items-center justify-content-center instagram-box'>
                                <div className="col-6 text-center"><img className='w-75' src={instagramLogo} alt="instagram" /></div>
                                <div className="col-6 fs-5">09193946701</div>
                            </div>
                            <div className='row align-items-center justify-content-center youtub-box'>
                                <div className="col-6 text-center"><img className='w-75' src={youtubLogo} alt="youtub" /></div>
                                <div className="col-6 fs-5">09193946701</div>
                            </div>
                            <div className='row align-items-center justify-content-center aparat-box'>
                                <div className="col-6 text-center"><img className='w-75' src={aparatLogo} alt="aparat" /></div>
                                <div className="col-6 fs-5">09193946701</div>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='video-box my-4'>
                <Col lg={4}>
                    <h4 className='text-center'>مسیر یابی تصویری بر روی نقشه :</h4>
                    <div className='mt-3 mt-lg-5'>
                        <h5>مسیر اول :</h5>
                        <p className='text-center'>خروج از  کناره رو بزرگراه فتح غرب به شرق
                            به سمت خیابان میرقادری
                            نرسیده به بلوار طالقانی پلاک 128
                        </p>
                    </div>
                    <div className='mt-3 mt-lg-5'>
                        <h5>مسیر دوم :</h5>
                        <p className='text-center'>
                            خیابان میرقادری به سمت غرب
                            بلوار طالقانی - خیابان وادی زاده
                            خیابان یخچال - خیابان میرقادری به سمت شرق
                            نرسیده به بلوار طالقانی پلاک 128
                        </p>
                    </div>
                </Col>
                <Col lg={8}>
                    <div style={{ background: "#ddd", borderRadius: "10px", height: "100%" }}></div>
                </Col>
            </Row>
        </Container >

    )

}