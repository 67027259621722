import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import trakingImg from "./../../Image/traking.png"
import trakingBg from "./../../Image/traking-bg.jpg"
import allOrders from './../../Data/orders'
import trak1 from "./../../Image/trak1.png"
import trak2 from "./../../Image/trak2.png"
import trak3 from "./../../Image/trak3.png"
import trak4 from "./../../Image/trak4.png"
import NavLinks from '../NavLinks/NavLinks'
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";

import "./Tracking.css"

export default function Tracking() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [trackingCode, setTrackingCode] = useState("");
    const [tracking, setTracking] = useState({
        code: '',
    });

    const onSubmit = (ev) => {
        ev.preventDefault();
        setLoading(true);
        axiosClient.get('/tracking/' + tracking.code)
            .then((data) => {
                setTrackingCode(data.data.data);
                setError("");
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    setTrackingCode("");
                    setError("شماره سفارش شما اشتباه است");
                } else {
                    console.error(err);
                }
            });
    };

    const link = [{
        name: 'پیگیری سفارشات',
        link: '/پیگیری-سفارشات/',
    }];
    return (
        <Container>
            <NavLinks {...link} />
            <Row className='my-5 justify-content-evenly'>
                <Col lg={5} className='d-flex align-items-center order-2 order-lg-1'>
                    <div className='text-center'>
                        <div><h3 className='text-center'>لطفا شماره سفارش    خود را وارد کنید</h3></div>
                        <div className='tracing-code-box mt-2 mt-lg-5' style={{ backgroundImage: `url(${trakingBg})` }}>
                            <form onSubmit={onSubmit}>
                                <div>
                                    <input
                                        className='input-review px-2 fs-7'
                                        placeholder='شماره سفارش خود را وارد کنید'
                                        type="text"
                                        value={tracking.code}
                                        onChange={ev => setTracking({ ...tracking, code: ev.target.value })}
                                    />
                                    <span className='text-danger'>
                                        {error}
                                    </span>
                                </div>
                                <div>
                                    <button className='check-traking'>رهگیری</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Col>
                <Col lg={5} className='text-center mb-3 mb-lg-0 order-1 order-lg-2'>
                    <img src={trakingImg} className='img-fluid img-traking' alt="" />
                </Col>
            </Row>
            {trackingCode.status != null && (
                <div className='mt-5'>
                    <div className='mt-5 mb-3'>
                        <table className='w-100 traking-table' style={{ lineHeight: "3" }}>
                            <tr className='order-header mb-3'>
                                <th className='order-number text-center'>نام خریدار</th>
                                <th className='order-item-traking text-center d-none d-lg-block'>آیتم ها</th>
                                <th className='order-date text-center'> مقصد</th>
                                <th className='order-price text-center'>مبلغ سفارش</th>
                            </tr>

                            {allOrders.map((ord, index) => (
                                <>
                                    <tr key={index} className='order-item-box my-3 py-3'>
                                        <td className='text-center'>ممد</td>
                                        <td className='text-center d-none d-lg-block'>
                                            {ord.orderProp.map((ordprop => (
                                                <span>{ordprop.name}</span>
                                            )))}
                                        </td>
                                        <td className='text-center'>تهران</td>
                                        <td className='text-center'>{ord.totalPrice}</td>
                                    </tr>
                                    <tr className='d-lg-none order-item-box-2'>
                                        <td colSpan="4" className='text-center'>
                                            {ord.orderProp.map((ordProp, propIndex) => (
                                                <Row key={propIndex}>
                                                    <Col xs={6} className='order-item-phone'>{ordProp.name}</Col>
                                                    <Col xs={3} className='order-item-phone'>{ordProp.number}</Col>
                                                    <Col xs={3} className='order-item-phone'>{ordProp.price}</Col>
                                                </Row>
                                            ))}
                                        </td>
                                    </tr>
                                </>
                            ))}

                        </table>
                    </div>
                </div>
            )}

            <div className="mb-5">
                <h5 className='text-center my-4 my-lg-5'>
                    {trackingCode.status == 0 && (
                        <span className='text-success'>سفارش شما با موفقیت ثبت شد و در صف بسته بندی جهت ارسال می باشد.</span>
                    )}
                    {trackingCode.status == 1 && (
                        <span className='text-success'>سفارش شما با موفقیت  بسته بندی شد و تحویل تیباکس داده شده.</span>
                    )}
                    {trackingCode.status == 2 && (
                        <span className='text-success'>سفارش شما با موفقیت تحویل تیباکس داده شده .</span>
                    )}
                    {trackingCode.status == 3 && (
                        <span className='text-success'>سفارش شما با موفقیت تکمیل شد.</span>
                    )}
                </h5>
                <div className='row gy-4 justify-content-center'>

                    <div className='col-12 col-lg-1 justify-content-center align-items-center text-center'>
                        <img className={trackingCode.status == 0 || trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'active-trak img-trak' : 'img-trak'} style={{ width: "90px" }} src={trak1} alt="" />
                        <span>ثبت موفق</span>
                    </div>
                    <div className='col-12 col-lg-2 d-flex box-dot justify-content-between align-items-center'>
                        <span className={trackingCode.status == 0 || trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 0 || trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 0 || trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 0 || trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 0 || trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                    </div>
                    <div className='col-12 col-lg-1 justify-content-center align-items-center text-center'>
                        <img className={trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'active-trak img-trak' : 'img-trak'} style={{ width: "90px" }} src={trak2} alt="" />
                        <span>در صف بسته بندی</span>
                    </div>
                    <div className='col-12 col-lg-2 d-flex box-dot justify-content-between align-items-center'>
                        <span className={trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 1 || trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                    </div>
                    <div className='col-12 col-lg-1 justify-content-center align-items-center text-center'>
                        <img className={trackingCode.status == 2 || trackingCode.status == 3 ? 'active-trak img-trak' : 'img-trak'} style={{ width: "90px" }} src={trak3} alt="" />
                        <span>تحویل تیباکس داده شده</span>
                    </div>
                    <div className='col-12 col-lg-2 d-flex box-dot justify-content-between align-items-center'>
                        <span className={trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                        <span className={trackingCode.status == 2 || trackingCode.status == 3 ? 'dot dot-green' : 'dot'}></span>
                    </div>
                    <div className='col-12 col-lg-1 justify-content-center align-items-center text-center'>
                        <img className={trackingCode.status == 3 || trackingCode.status == 3 ? 'active-trak img-trak' : 'img-trak'} style={{ width: "90px" }} src={trak4} alt="" />
                        <span>تکمیل شده</span>
                    </div>
                </div>
            </div>
        </Container>
    )
}
